<template>
  <div style="position: relative;padding-top: 300px;">
    <div class="advert">
      <el-carousel height="360px">
        <el-carousel-item v-for="item in swiperList" :key="item.advertId">
          <img :src="item.imgUrl" style="width: 100%;height: 100%;object-fit: cover;" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="w">
      <!-- 轮播图和热版 -->
      <el-row :gutter="20">
        <el-col :span="16">
          <!-- <el-carousel height="400px">
          <el-carousel-item v-for="item in swiperList" :key="item.advertId">
            <img :src="item.imgUrl" style="width: 100%;height: 100%;" alt="">
          </el-carousel-item>
        </el-carousel> -->
          <img src="https://oss.culturalcloud.net/furong/202301/02211117oux3.png" style="width: 100%;height: 270px;"
            alt="">
        </el-col>
        <el-col :span="8">
          <el-card shadow="never" class="box-card" style="height: 270px;" :body-style="{ padding: '0' }">
            <div slot="header" class="clearfix">
              <span class="title">数据统计</span>
            </div>
            <div class="card-contet">
              <div class="card-box" style="border-right: 1px solid #ccc;border-bottom: 1px solid #ccc;">
                <img src="https://oss.culturalcloud.net/furong/202301/02212311m14v.png" alt="">
                <div>
                  <div class="data">
                    {{parseInt((new Date().getTime() - new Date('2022-11-15 00:00:00').getTime())/1000/60/60/24)}}<span
                      class="unit">/天</span>
                  </div>
                  <div class="card-title">平台运行天数</div>
                </div>
              </div>
              <div class="card-box" style="border-bottom: 1px solid #ccc;">
                <img src="https://oss.culturalcloud.net/furong/202301/02212851wee0.png" alt="">
                <div>
                  <div class="data">{{data.pv}}<span class="unit">/次</span></div>
                  <div class="card-title">访问人次</div>
                </div>
              </div>
              <div class="card-box" style="border-right: 1px solid #ccc">
                <img src="https://oss.culturalcloud.net/furong/202301/02212930byrm.png" alt="">
                <div>
                  <div class="data">{{data.userCount+234}}<span class="unit">/位</span></div>
                  <div class="card-title">用户数</div>
                </div>
              </div>
              <div class="card-box">
                <img src="https://oss.culturalcloud.net/furong/202301/02213011trhv.png" alt="">
                <div>
                  <div class="data">{{data.collectionCount+86}}<span class="unit">/次</span></div>
                  <div class="card-title">收藏总数</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="20">
        
        <el-col :span="18" :offset="0">
          <div v-for="item in hotList" :key="item.id" class="list-box" style="margin-top: 16px;">
            <div class="list-title">{{item.title}}</div>
            <div class="list">
              <div class="left" @click="$router.push({ path: '/detail/'+item.list[0].bookId })">
                <img :src="item.list[0].titleImg" alt="">
                <div class="book-info">
                  <div class="book-title">{{item.list[0].title}}</div>
                  <div class="book-author">{{item.list[0].author}}</div>
                  <div class="book-description">{{item.list[0].title}}</div>
                </div>
              </div>
              <div class="right">
                <div class="book-item" v-for="(book,index) in item.list" :key="index" v-if="index>0" @click="$router.push({ path: '/detail/'+book.bookId })">
                  <img :src="book.titleImg" alt="">
                  <div class="book-info">
                    <div class="book-title">{{book.title}}</div>
                    <div class="book-author">{{book.author}}</div>
                    <div class="book-description">{{book.description}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-card class="rank" shadow="never" :body-style="{ padding: '20px' }">
                <div slot="header">
                  <span style="font-weight: bold;">阅读排行榜</span>
                </div>
                <!-- card body -->
                <div class="rank-list">
                  <div class="rank-box" v-for="(item,index) in rankList" :key="item.bookId"
                    @click="$router.push({ path: '/detail/'+item.bookId })">
                    <template v-if="index===0">
                      <div class="img">
                        <img width="100" :src="item.titleImg" alt="" />
                        <i class="tag">{{index+1}}</i>
                      </div>
                      <div class="rank-info">
                        <p class="title es-1">{{item.title}}</p>
                        <p class="author">{{item.author}}</p>
                        <p class="publish">{{item.publish}}</p>
                        <p class="des es-2">{{item.description}}</p>
                      </div>
                    </template>
                    <template v-else>
                      <div class="rank-normal">
                        <i class="tag" :style="{backgroundColor:index<3?'#f13670':''}">{{index+1}}</i>
                        <p class="es-1 title">{{item.title}}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </el-card>

            </el-col>
            <el-col :span="12" :offset="0">
              <el-card class="rank" shadow="never" :body-style="{ padding: '20px' }">
                <div slot="header">
                  <span style="font-weight: bold;">新书推荐榜</span>
                </div>
                <!-- card body -->
                <div class="rank-list">
                  <div class="rank-box" v-for="(item,index) in newBook" :key="item.bookId"
                    @click="$router.push({ path: '/detail/'+item.bookId })">
                    <template v-if="index===0">
                      <div class="img">
                        <img width="100" :src="item.titleImg" alt="" />
                        <i class="tag">{{index+1}}</i>
                      </div>
                      <div class="rank-info">
                        <p class="title es-1">{{item.title}}</p>
                        <p class="author">{{item.author}}</p>
                        <p class="publish">{{item.publish}}</p>
                        <p class="des es-2">{{item.description}}</p>
                      </div>
                    </template>
                    <template v-else>
                      <div class="rank-normal">
                        <i class="tag" :style="{backgroundColor:index<3?'#f13670':''}">{{index+1}}</i>
                        <p class="es-1 title">{{item.title}}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </el-card>

            </el-col>

          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-card class="sort" :body-style="{padding:0}">
            <div slot="header" class="clearfix" >
              <span class="header-title">图书分类</span>
            </div>
            <div v-for="(item,index) in bookType" :key="index" class="text"
              @click="$router.push({path:'/list',query: {type:item.dictLabel}})">
              <span class="tag">{{item.dictLabel.split(' ')[0] }}</span>
              <span class="title">{{item.dictLabel.split(' ')[1] }}</span>

            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {
    getHotBook,
    getBookList,
    getType,
    getSwiper,
    getRank,
    getData
  } from "@/api";
  export default {
    name: "home",
    data() {
      return {
        swiperList: [],
        rankList: [],
        bookType: [],
        newBook: [],
        // mustBook:[],
        // partyBook:[]
        hotList: [],
        data: {}



      };
    },
    created() {
      this.getSwiperList()

      this.getTypeList()
      this.getHotList()

      this.getRankList()
      this.getNewBook()

      this.getDataNum()
      // this.getbook('古人各阶段读的书')
      // this.getbook('党史学习教育')
      // console.log(this.$router.currentRoute.path)
    },
    methods: {
      getDataNum() {
        getData().then(res => {
          if (res.code === 200) {
            // console.log(res);
            this.data = res.data
          }
        })
      },
      getNewBook() {
        getRank({
          orderByColumn: 'createTime'
        }).then(res => {
          if (res.code === 200) {
            this.newBook = res.rows
          }
        })
      },
      getHotList() {
        getHotBook({
          pageSize: 7
        }).then(res => {
          if (res.code === 200) {
            this.hotList = res.data
          }
        })
      },
      getbook(type) {
        getBookList({
          pageNum: 1,
          pageSize: 6,
          type
        }).then(res => {
          if (res.code === 200) {
            // console.log(res);
            this.mustBook = res.rows
          }
        })
      },
      // getbook(type){
      //   getBookList({pageNum:1,pageSize:6,type}).then(res=>{
      //     if(res.code === 200){
      //       // console.log(res);
      //       this.partyBook=res.rows
      //     }
      //   })
      // },
      getTypeList() {
        getType().then(res => {
          if (res.code === 200) {
            let arr = res.data
            // arr.unshift({dictLabel:'全部'})
            this.bookType = arr
          }
        })
      },
      getRankList() {
        getRank().then(res => {
          if (res.code === 200) {
            this.rankList = res.rows
          }
        })
      },
      getSwiperList() {
        getSwiper().then(res => {
          if (res.code === 200) {
            this.swiperList = res.rows
          }
        })
      },

    },
  };
</script>
<style lang="scss">
  .box-card {
    .el-card__header {
      padding: 10px 30px;
    }
  }
</style>
<style lang="scss" scoped>
  ::v-deep .sort {
    position: sticky;
    top: 30px;

    .el-card__header {
      // background-color: #6cb0f3;
    }

    .header-title {
      // text-align: center;
      // color: #fff;
      font-size: 20px;
      // font-weight: bold;
    }

    .text {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      height: 60px;
      cursor: pointer;
      padding: 0 10px;

      .tag {
        // border: 1px solid #30b08f;
        background-color: #6CB0F3;
        color: #fff;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);
        font-weight: bold;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
      }

      .title {
        // width: 150px;
        margin-left: 10px;
      }

      &:hover {
        color: #fff;
        background-color: #30b08f;
      }
    }

    .active {
      font-weight: bold;
      color: #fff;
      background-color: #30b08f;
      text-decoration: underline;
    }



  }

  .rank {
    border-top: 5px solid #30b08f;

    .rank-list {
      .rank-box {
        display: flex;
        cursor: pointer;
        // align-items: center;


        .img {
          position: relative;
          width: 30%;

          .tag {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            background-color: #f13670;
            text-align: center;
            line-height: 30px;
            color: #fff;
          }
        }

        .rank-info {
          width: 70%;

          .title {
            font-weight: bold;
            font-size: 20px;
          }

          .publish {
            color: #999;
          }

          .author {
            color: #999;
          }

          .des {
            color: #999;
          }

        }

        .rank-normal {
          display: flex;
          align-items: center;

          margin-bottom: 10px;

          .tag {
            text-align: center;
            background-color: #999;
            color: #fff;
            line-height: 30px;
            text-align: center;
            width: 30px;
            height: 30px;
            margin-right: 20px;
          }

          .title {
            width: 400px;
          }
        }
      }
    }
  }

  .book-container {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    // border: 1px solid;

    .book-list {
      width: 20%;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      // border: 1px solid #ccc;
      padding: 10px;

      &:hover {
        box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.4);
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      .img {
        width: 150px;
        height: 200px;
      }

      .book-info {
        // margin-left: 30px;
        // width: 60%;

        .title {
          // line-height: 56px;
          height: 56px;
          font-size: 20px;
          font-weight: bold;
        }

        li {
          line-height: 28px;
          display: flex;
        }

        span {
          color: #99a9bf;
          width: 80%;
        }
      }
    }
  }

  .text {
    font-size: 14px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }

  .box-card {
    border-radius: 16px;

    //   height: 350px;
    .title {
      color: #30b08f;
      // border-bottom: 2px solid #30b08f;
      position: relative;
    }

    .title::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 3px;
      width: 3px;
      height: 16px;
      background-color: #30b08f;
    }

    .card-contet {
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      .card-box {
        width: 50%;
        height: 115px;
        // background-color: #30b08f;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 20px;

        img {
          margin-right: 20px;
        }

        i {
          font-size: 30px;
        }

        .data {
          font-size: 18px;
          font-weight: bold;
        }

        .card-title {
          font-size: 14px;
        }

        .unit {
          font-size: 14px;
          font-weight: normal;
        }
      }

    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 350px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .advert {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .list-box {
    margin-bottom: 50px;

    .list-title {
      font-size: 20px;
      color: #000000;
      height: 50px;
      margin-bottom: 50px;
      border-bottom: 1px dashed #999999;
    }

    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 400px;

      .left {
        width: 220px;
        height: 100%;
        display: flex;
        flex-direction: column;

        img {
          width: 220px;
          height: 300px;
        }

        .book-info {
          width: 100%;
          height: calc(100% - 300px);
          padding: 15px;
          box-sizing: border-box;
          background-color: #F5F5F5;

          .book-title {
            font-size: 20px;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .book-author {
            font-size: 13px;
            line-height: 28px;
            color: rgba(0, 0, 0, .7);
          }

          .book-description {
            font-size: 13px;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, .4);
          }
        }
      }

      .right {
        width: 650px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .book-item {
          width: 45%;
          height: 120px;
          display: flex;
          justify-content: space-between;

          img {
            width: 90px;
            height: 100%;
          }

          .book-info {
            width: calc(100% - 100px);
            height: 100%;
            padding: 10px 0;

            .book-title {
              font-size: 18px;
              color: #000000;
              font-weight: bold;
              line-height: 36px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .book-author {
              font-size: 13px;
              color: rgba(0, 0, 0, 0.7);
              line-height: 34px;
            }

            .book-description {
              font-size: 13px;
              color: rgba(0, 0, 0, 0.4);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
</style>
